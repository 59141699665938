<template>
  <div class="login">
    <div class="box">
      <div class="title">欢迎使用后台管理系统</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="loginForm"
        label-width="60px"
        class="form-style"
      >
        <el-form-item label="账号" prop="account">
          <el-input type="text" v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
      </el-form>
      <el-button class="login-btn" @click="submit">登录</el-button>
    </div>
  </div>
</template>
<script>
import md5 from "md5";
export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      try {
        this.$refs["loginForm"].validate(async (valid) => {
          if (valid) {
            const params = new FormData();
            params.append("account", this.ruleForm.account);
            params.append("password", md5(this.ruleForm.password));
            const { data: res } = await this.$http.post("admin/login", params);
            if (res.code === 0) {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("user", JSON.stringify(res.data.user));
              this.$message.success("登陆成功！");
              this.$router.push({ name: "admin" });
            } else {
              this.$message.error(`${res.msg}`);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../../src/assets/homepage/login_back.jpg");
  background-size: 100% 100%;
}
.box {
  position: absolute;
  width: 600px;
  height: 360px;
  left: 50%;
  top: 50%;
  border-radius: 10px;
  transform: translate(-50%, -80%);
  border: 1px solid rgb(224, 219, 219);
  background-color: rgba(235, 231, 231, 0.3);
}
.title {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  font-weight: 700;
}
.form-style {
  width: 500px;
  height: 100px;
  margin: 50px auto;
}
.login-btn {
  display: block;
  width: 440px;
  text-align: center;
  margin-left: 111px;
  letter-spacing: 18px;
  padding-left: 37px;
}
</style>
