<template>
  <el-card class="gscy">
    <el-card class="form-card">
      <el-form
        :model="ruleForm"
        :inline="true"
        ref="ruleForm"
        label-width="100px"
        class="el-form-s"
      >
        <div v-for="item in 6" :key="item" class="pic_div">
          <el-form-item>
            <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg,.png"
              :auto-upload="false"
              :on-remove="
                (e) => {
                  handleRemove(e, item);
                }
              "
              :on-change="
                (e) => {
                  handleAvatarSuccess(e, item);
                }
              "
            >
              <img
                v-if="pictureList[item - 1]"
                :src="pictureList[item - 1].pic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p class="pic_p">第{{ item }}张</p>
          </el-form-item>
        </div>
      </el-form>
      <div style="height: 40px">
        <!-- <el-button style="float: right" @click="submit">提交</el-button> -->
      </div>
    </el-card>
  </el-card>
</template>
<script>
// import { changeFileIntoBase64 } from "@/utils/index";
import global_msg from "../../../../global";
export default {
  name: "cpjs",
  data() {
    return {
      type: 2,
      tempId: "",
      imgPath: global_msg.imgPath,
      ruleForm: {
        fileList1: "",
      },
      pictureList: [],
      fileList1: {
        id: "",
        pic: "",
        relation: "",
        sort: "",
        type: "",
      },
    };
  },
  created() {
    //公司产业图片类型type=4；
    this.onLoadImage();
  },
  methods: {
    async handleAvatarSuccess(file, key) {
      //   this[key] = URL.createObjectURL(file.raw);
      //   this.ruleForm[key] = await changeFileIntoBase64(file.raw);
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("type", this.type);
      formData.append("sort", key - 1);
      try {
        const { data: res } = await this.$http.post(
          "admin/uploadPictures",
          formData
        );
        console.log("res:" + res);
        if (res.code === 0) {
          // this.pictureList[key - 1] = res.data;
          // this.pictureList[key - 1].pic = global_msg.imgPath + res.data.pic;
          // console.log(key - 1);
          // console.log(this.pictureList[key - 1].pic);
          this.onLoadImage();
        }
      } catch (error) {
        console.log("上传失败");
        console.error(error);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    async handleRemove(e, key) {
      let formData = new FormData();
      formData.append("id", this.pictureList[key-1].id);
      const { data: res } = await this.$http.post("admin/deletePic", formData);
      if (res.code === 0) {
        this.onLoadImage();
        this.$message.success("删除成功");
      }
    },
    async onLoadImage() {
    try {
      const { data: res } = await this.$http.get("newController/pictures", {
        params: { type: 2 },
      });
      if (res.code === 0) {
        this.pictureList = res.data;
        this.pictureList.forEach((picture) => {
          picture.pic = global_msg.imgPath + picture.pic;
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  },
};
</script>
<style scoped>
.gscy {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 1600px;
}
.pic_div {
  display: flex;
  flex: 1;
  float: left;
  margin-right: 30px;
}
.pic_p {
  text-align: center;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader >>> .el-upload-dragger {
  width: 178px;
  height: 178px;
}

#deleteBtn {
  width: 100%;
  height: 30px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hide_box >>> .el-upload--picture-card {
  display: none !important;
}
</style>
