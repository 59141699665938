<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">公司概况</div>
        <div class="part2-text2">欢迎加入我们</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 > 公司概况</div>
      </div>
      <div class="part4">
        <div class="part4-div1">
          <div class="part4-div2">
            <div class="part4-div2-text1">关于企业</div>
            <div class="part4-div2-text2">
              {{ aboutCompany }}
            </div>
          </div>
          <div class="part4-div3">
            <img src="../../src/assets/homepage/蒙版(17).png" />
          </div>
        </div>
      </div>
      <div class="part5">
        <div class="part5-div1">
          <div class="part5-div1-text1">企业文化</div>
          <div class="part5-div1-div">
            <div class="part5-div1-div-div1">
              <div class="part5-div1-div-div1-div1">
                <img src="../../src/assets/homepage/编组 5.png" />
              </div>
              <div class="part5-div1-div-div1-text1">企业定位</div>
              <div class="part5-div1-div-div1-text2">
                生产、销售：纺织品织造，漂染、印花产品
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 10.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业理念</div>
                <div class="part5-div1-div-div1-text2">
                  责任 &nbsp;感恩<br />
                  守正 &nbsp;创新<br />
                  融合&nbsp; 发展
                </div>
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 14.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业使命</div>
                <div class="part5-div1-div-div1-text2">
                  科技创新引领产业发展<br />卓越品质源自印染
                </div>
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 15.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业愿景</div>
                <div class="part5-div1-div-div1-text2">
                  汇才聚智 <br />
                  赋能产业
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part6">
        <div class="part6-div">
          <div class="part6-text1">{{ leader.title }}</div>
          <div class="part6-div2">
            <div class="part6-div2-text1">
              {{ leader.content }}
            </div>
            <div class="part6-div3">
              <div class="part6-div3-text">执行总裁:</div>
              <div class="part6-div3-img">
                <h1>{{ leader.author }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="part8">
        <div class="part8-div">
          <div class="part8-div-text">{{officeEnvironment.title}}</div>
          <div class="part8-div2">
            <div class="part8-div2-div1">
              <img :src="officeEnvironment.pictureList[0].pic" />
            </div>
            <div class="part8-div2-div2">
              <img :src="officeEnvironment.pictureList[1].pic" />
              <img
              :src="officeEnvironment.pictureList[2].pic"
                style="margin-left: 13px"
              />
              <img
              :src="officeEnvironment.pictureList[3].pic"
                style="margin-top: 9px"
              />
              <img
              :src="officeEnvironment.pictureList[4].pic"
                style="margin-left: 13px"
              />
            </div>
          </div>
        </div>
      </div> -->
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      aboutCompany:
        "肇庆市佳利印染有限公司在广东肇庆高要区注册，至今已成立19年112天，在法人黄佳祥的带领下，公司目前处于开业状态，如您需要肇庆市佳利印染有限公司的产品与服务，可到肇庆市高要区莲塘镇蚌岗工业园内来访，欢迎广大客户与公司合作洽谈业务，公司主要产品与服务见企业经营范围。公司经营范围包括：生产、销售：纺织品织造，漂染、印花产品等。",
      leader: {
                id: '',
                title: '总裁寄语',
                content: '打造前程集团公司自成立以来，始终保持战略定力，调整和优化产业结构，丰富产业布局，完成了金融、科技、电商、职教、幼教各业务板块的协同发展，以金融助力区域经济建设，以科技推进智慧建设，以产业带动专业建设，实现产教深度融合。 面对复杂多样、瞬息万变的市场形势，集团公司将保持既定的战略方向，发扬“脚踏实地、志存高远”的企业精神，坚持“责任、感恩、守正、创新、融合、发展”的企业理念，坚守“科技创新引领产业发展，产教融合培育技能人才”的企业使命，传承精髓，创新发展。未来，前程人要勇做守正传承的孺子牛、创新发展的拓荒牛、艰苦奋斗的老黄牛；立德树人守初心，守正创新担使命；有打硬仗、闯难关的坚韧，敢担当、善作为的干劲；脚踏实地，创新不辍，实现“汇才聚智，赋能产业”的愿景，开创集团公司发展的新高度。',
                type: '',
                createdTime: '',
                updateTime: '',
                createdUser: '',
                updateUser: '',
                author: '黄佳祥'
      },
      officeEnvironment: {},
    };
  },
  created() {
    this.onLoadAboutCompany();
    this.onLoadAboutLeader();
    this.onLoadOfficeEnvironment();
  },
  methods: {
    async onLoadAboutCompany() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: { pageSize: 1, currentPage: 1, type: 6 },
        });
        if (res.code === 0) {
          this.ObjectList = res.list;
          if (res.list[0] !== null) {
            this.aboutCompany = res.list[0].content;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadAboutLeader() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: { pageSize: 1, currentPage: 1, type: 4 },
        });
        if (res.code === 0) {
          if (res.list[0] !== null) {
            this.leader = res.list[0];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadOfficeEnvironment() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: { pageSize: 5, currentPage: 1, type: 5 },
        });
        if (res.code === 0) {
          console.log(res);
          this.officeEnvironment = res.list[0];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.all {
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
}
.part2 {
  width: 1920px;
  height: 300px;
  background-image: url("../../src/assets/homepage/矿山1.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  width: 108px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  padding-top: 18px;
  margin: 0 auto;
}
.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 15px;
}

.part4 {
  width: 1920px;
  /* height: 785px; */
  margin: 0 auto;
  margin-top: 5px;
  background: url("../../src/assets/homepage/蒙版.png");
}

.part4-div1 {
  width: 1200px;
  /* height: 785px; */
  margin: 0 auto;
  /* display: flex; */
}

.part4-div2 {
  /* border: gold 1px solid; */
  width: 611px;
  height: 464px;
  display: inline-block;
}

.part4-div2-text1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
  margin-top: 110px;
}

.part4-div2-text2 {
  width: 611px;
  height: 280px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  margin-top: 37px;
}

.part4-div3 {
  /* float: right; */
  margin-top: 37px;
  display: inline-block;
  margin-left: 98px;
}

.part4-div4 {
  height: 144px;
  width: 1200px;
  margin-top: 60px;
}

.part4-div4-div1 {
  height: 84px;
  width: 166px;
  display: inline-block;
}

.part4-div4-div1-p1 {
  margin: 0;
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 84px;
}

.part4-div4-div1-p2 {
  margin: 0;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 24px;
}

.part4-div4-div1-div1 {
  display: inline-block;
}

.part4-div4-div1-div2 {
  display: inline-block;
  height: 74px;
  width: 69px;
  margin-left: 11px;
}

.part4-div4-div2 {
  display: inline-block;
  margin-left: 144px;
}

.part5 {
  width: 1920px;
  height: 598px;
  /* border: red 1px solid; */
}

.part5-div1 {
  /* border: blue 1px solid; */
  width: 1200px;
  height: 598px;
  margin: 0 auto;
}

.part5-div1-text1 {
  width: 152px;
  height: 45px;
  /* border: 1px orchid solid; */
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
  margin: 0 auto;
  margin-top: 84px;
}

.part5-div1-div {
  /* border: 1px dodgerblue solid; */
  width: 1200px;
  height: 348px;
  margin-top: 41px;
}

.part5-div1-div-div1 {
  width: 288px;
  height: 348px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.part5-div1-div-div1-div1 {
  padding-top: 44px;
}

.part5-div1-div-div1-text1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 32px;
  margin-top: 24px;
}

.part5-div1-div-div1-text2 {
  width: 221px;
  height: 66px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  margin: 30px 34px 62px 33px;
}

.part5-div1-div2 {
  margin-left: 16px;
  display: inline-block;
}

.part6 {
  width: 1920px;
  height: 801px;
  background: url("../../src/assets/homepage/bg4.png");
}

.part6-text1 {
  width: 152px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  /* border: solid 1px red; */
  margin: 0 524px 55px 524px;
  text-align: center;
  padding-top: 84px;
}

.part6-div {
  width: 1200px;
  height: 801px;
  /* border: solid 1px red; */
  margin: 0 auto;
}

.part6-div2 {
  width: 1200px;
  height: 465px;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* border: solid 1px red; */
}

.part6-div2-text1 {
  width: 1000px;
  height: 262px;
  padding-top: 71px;
  padding-left: 100px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}

.part6-div3 {
  width: 228px;
  height: 50px;
  margin: 0 auto;
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.part6-div3-text {
  display: inline-block;
  width: 105px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}

.part6-div3-img {
  display: inline-block;
  font-family: "YourFontName", cursive;
  margin-left: 29px;
  width: 100px;
}

.part7 {
  background: url("../../src/assets/homepage/蒙版(18).png");
  width: 1918px;
  height: 527px;
  position: relative;
}

.part7-div {
  margin: 0 auto;
  border: 1px solid red;
  width: 1200px;
  height: 527px;
}

.part7-div-text {
  width: 152px;
  height: 45px;
  /* border: 1px orchid solid; */
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
  margin: 0 auto;
  margin-top: 64px;
}

.part7-div2 {
  border: 1px solid red;
  margin-top: 73px;
  height: 260px;
}

.part7-div2-img1 {
  width: 32px;
  height: 32px;
  border: 1px solid blue;
  position: absolute;
  top: 170px;
  left: 358px;
}

.part7-div2-img2 {
  width: 32px;
  height: 32px;
  border: 1px solid blue;
  position: absolute;
  top: 170px;
  right: 359px;
}

.dian {
  width: 10px;
  height: 10px;
  background: #999999;
  border-radius: 51px;
  margin-left: 70px;
}

.xian {
  width: 2px;
  height: 177px;
  /* border: 0.5px solid #979797; */
  margin-left: 74px;
  background: #979797;
}

.dianxian {
  display: inline-block;
}

.text {
  display: inline-block;
  width: 60px;
  height: 50px;
  border: 1px solid red;
}

.part7-div2-div1 {
  display: inline-block;
}

.part7-div2-div1-text1 {
  width: 53px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 33px;
  /* padding-top: 60px; */
  margin: 0;
  /* position: absolute;
    top: 252px;
    left: 449px; */
}

.part8 {
  height: 714px;
  width: 1918px;
}

.part8-div {
  margin: 0 auto;
  width: 1200px;
  height: 714px;
}

.part8-div-text {
  width: 160px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  margin-top: 64px;
  margin: 64px auto;
}

.part8-div2 {
  width: 1200px;
  height: 468px;
}

.part8-div2-div1 {
  display: inline-block;
  width: 593px;
  height: 468px;
}

.part8-div2-div2 {
  display: inline-block;
  width: 592px;
  height: 468px;
  margin-left: 15px;
}
.part8-div2-div2 img {
  width: 47%;
  height: 48.5%;
}

.part9 {
  height: 725px;
  width: 1918px;
}

.part9-center {
  margin: 0 auto;
  border: 1px solid blue;
  width: 1200px;
  height: 725px;
}

.part9-text1 {
  margin: 80px auto;
  width: 128px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
}
.part9-text2 {
  width: 907px;
  height: 64px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
  text-align: center;
  margin: 40px auto;
}
</style>
