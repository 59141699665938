<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">联系我们</div>
        <div class="part2-text2">欢迎联系我们</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 > 联系我们</div>
      </div>
      <div class="part4">
        <div class="part4-div1">
          <div class="part4-div1-text1">联系我们</div>
          <div class="part4-div1-text2">contact us</div>
        </div>
        <div class="part4-div2">
          <div class="part4-div2-div1">
            <div class="part4-div2-div1-text1">
              <p>热线电话</p>
              <span>8463901 / 8463902</span>
            </div>
            <!-- <div class="part4-div2-div1-text2">
              <p>教务合作</p>
              <span>15888888888</span>
            </div> -->
          </div>
          <div class="part4-div2-div2">
            <div class="part4-div2-div1-text1">
              <p>公司邮箱</p>
              <span>jiali13702614268@163.com</span>
            </div>
            <div class="part4-div2-div1-text2">
              <p>公司地址</p>
              <span>广东省肇庆市高要区莲塘镇蚌岗工业园内</span>
            </div>
          </div>
        </div>
        <div class="part4-div3">
          <div class="part4-div3-div">
            <!-- <img src="../assets/homepage/编组 1.png" />
            <span>关注微信公众号</span> -->
          </div>
        </div>
      </div>
      <div class="part5">
        <img src="../assets/homepage/location.jpg" />
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  },
};
</script>
<style scoped>
.all {
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
}

.part2 {
  width: 1920px;
  height: 300px;
  background-image: url("../../src/assets/homepage/bg5.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  width: 144px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f4f4f4;
  line-height: 33px;
  padding-top: 18px;
  margin: 0 auto;
  margin-left: 886px;
}

.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 15px;
}

.part4 {
  height: 315px;
  width: 1200px;
  margin: 0 auto;
  /* border: red 1px solid; */
}

.part4-div1 {
  /* border: red 1px solid; */
  display: inline-block;
  width: 235px;
  height: 300px;
  vertical-align: top;
}

.part4-div1-text1 {
  width: 167px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #23337f;
  line-height: 45px;
  margin-top: 90px;
}

.part4-div1-text2 {
  width: 235px;
  height: 80px;
  font-size: 48px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f6f6f6;
  line-height: 40px;
  margin-top: 9px;
}

.part4-div2 {
  display: inline-block;
  width: 635px;
  height: 315px;
  /* border: red 1px solid; */
  vertical-align: top;
}

.part4-div2-div1 {
  width: 635px;
  height: 51px;
  /* border: blue 1px solid; */
  margin-top: 86px;
}

.part4-div2-div1-text1 {
  display: inline-block;
  margin-left: 148px;
}

.part4-div2-div1-text2 {
  display: inline-block;
  margin-left: 148px;
  margin-top: 36px;
}

.part4-div2-div1-text1 p {
  margin: 0;
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}

.part4-div2-div1-text1 span {
  width: 159px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 25px;
  margin-top: 4px;
}

.part4-div2-div1-text2 p {
  margin: 0;
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}

.part4-div2-div1-text2 span {
  width: 159px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 25px;
  margin-top: 4px;
}

.part4-div2-div2 {
  width: 635px;
  height: 51px;
  margin-top: 36px;
}

.part4-div3 {
  width: 322px;
  height: 315px;
  /* border: gold 1px solid; */
  display: inline-block;
}

.part4-div3-div img {
  margin-left: 204px;
  margin-top: 84px;
}

.part4-div3-div span {
  margin-left: 204px;
  margin-top: 4px;
  width: 98px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 20px;
}
.part5 {
  margin: 0 auto;
  width: 1200px;
  height: 571px;
  /* border: hotpink 1px solid; */
  /* margin-bottom: 100px; */
  margin-top: 36px;
  margin-bottom: 36px;
}
.part5 img {
  width: 100%;
  height: 100%;
}
</style>
