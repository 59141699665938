/*
 * @Descripttion: 工具集
 * @version: 1.1
 * @LastEditors: sakuya
 * @LastEditTime: 2021年7月20日10:58:41
 */

import CryptoJS from "crypto-js";

const tool = {};

/* localStorage */
tool.data = {
  set(table, settings) {
    var _set = JSON.stringify(settings);
    return localStorage.setItem(table, _set);
  },
  get(table) {
    var data = localStorage.getItem(table);
    try {
      data = JSON.parse(data);
    } catch (err) {
      return null;
    }
    return data;
  },
  remove(table) {
    return localStorage.removeItem(table);
  },
  clear() {
    return localStorage.clear();
  },
};

/*sessionStorage*/
tool.session = {
  set(table, settings) {
    var _set = JSON.stringify(settings);
    return sessionStorage.setItem(table, _set);
  },
  get(table) {
    var data = sessionStorage.getItem(table);
    try {
      data = JSON.parse(data);
    } catch (err) {
      return null;
    }
    return data;
  },
  remove(table) {
    return sessionStorage.removeItem(table);
  },
  clear() {
    return sessionStorage.clear();
  },
};

/* Fullscreen */
tool.screen = function (element) {
  var isFull = !!(
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement
  );
  if (isFull) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  }
};

/* 复制对象 */
tool.objCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

tool.deepClone = function (source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = tool.deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
};

/* 日期格式化 */
tool.dateFormat = function (date, fmt = "yyyy-MM-dd hh:mm:ss") {
  date = new Date(date);
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

/* 千分符 */
tool.groupSeparator = function (num) {
  num = num + "";
  if (!num.includes(".")) {
    num += ".";
  }
  return num
    .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
      return $1 + ",";
    })
    .replace(/\.$/, "");
};

/* 常用加解密 */
tool.crypto = {
  //MD5加密
  MD5(data) {
    return CryptoJS.MD5(data).toString();
  },
  //BASE64加解密
  BASE64: {
    encrypt(data) {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    },
    decrypt(cipher) {
      return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8);
    },
  },
  //AES加解密
  AES: {
    encrypt(data, secretKey) {
      const result = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return result.toString();
    },
    decrypt(cipher, secretKey) {
      const result = CryptoJS.AES.decrypt(
        cipher,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return CryptoJS.enc.Utf8.stringify(result);
    },
  },
};

// 提取富文本内容
tool.petNewDateFormat = function (str) {
  let reg = /<\/?.+?\/?>/g;
  for (let i = 0; i < str.length; i++) {
    if (str[i].pet_title) {
      let temp = str[i].pet_content;
      str[i].pic = tool.getFullTextImg(str[i].pet_content);
      str[i].pet_content = temp.replace(reg, "");
      str[i].pet_content = tool.uncodeUtf16(str[i].pet_content);
      str[i].pet_createDate = tool.dateFormat(str[i].pet_createDate);
      str[i].or_createTime = tool.dateFormat(str[i].or_createTime);
    } else {
      let temp = str[i].new_content;
      str[i].pic = tool.getFullTextImg(str[i].new_content);
      str[i].new_content = temp.replace(reg, "");
      str[i].new_content = tool.uncodeUtf16(str[i].new_content);
      str[i].createTime = tool.dateFormat(str[i].createTime);
    }
  }
};

// 提取分享富文本内容
tool.shareDateFormat = function (str) {
  let reg = /<\/?.+?\/?>/g;
  for (let i = 0; i < str.length; i++) {
    let temp = str[i].sh_content;
    str[i].pic = tool.getFullTextImg(str[i].sh_content);
    str[i].sh_content = temp.replace(reg, "");
    str[i].sh_content = tool.uncodeUtf16(str[i].sh_content);
    str[i].sh_createTime = tool.dateFormat(str[i].sh_createTime);
  }
};

// 去除html标签
tool.removeHtml = function (str) {
  let reg = /<\/?.+?\/?>/g;
  const t = str.replace(reg, "");
  return t;
};
// 获取图片链接
tool.getFullTextImg = function (str) {
  var data = [];
  str.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
    data.push(capture);
  });
  return data;
};
// 表情转码
tool.utf16toEntities = function (str) {
  var patt = /[\ud800-\udbff][\udc00-\udfff]/g;
  // 检测utf16字符正则
  str = str.replace(patt, function (char) {
    var H, L, code;
    if (char.length === 2) {
      H = char.charCodeAt(0);
      // 取出高位
      L = char.charCodeAt(1);
      // 取出低位
      code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00;
      // 转换算法
      return "&#" + code + ";";
    } else {
      return char;
    }
  });
  return str;
};

//表情解码
tool.uncodeUtf16 = function (str) {
  var reg = /#.*?;/g;
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
      L = ((code - 0x10000) % 0x400) + 0xdc00;
      return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
};

tool.formatTree = function (list) {
  // 定义最终需要返回的树形结构数据
  let treeData = [];
  // 对传入的数组进行遍历
  list.forEach((item) => {
    // 如果pid没有值,那就是顶级对象,直接添加进数组
    if (!item.com_pid) {
      treeData.push(item);
    }
    // 理解为二次遍历 ：每个对象都找一遍自己的孩子添加到children
    let objList = list.filter((data) => data.com_pid === item.com_id);
    if (objList.length) {
      item.children = objList;
    }
  });
  return treeData;
};
export default tool;
