var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"gscy"},[_c('el-card',{staticClass:"form-card"},[_c('el-form',{ref:"ruleForm",staticClass:"el-form-s",attrs:{"model":_vm.ruleForm,"inline":true,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"左1"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","accept":".jpg,.png","auto-upload":false,"on-remove":(e) => {
              _vm.handleRemove(e, 'fileList1');
            },"on-change":(e) => {
              _vm.handleAvatarSuccess(e, '1');
            }}},[(_vm.fileList1)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.fileList1.pic}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-form-item',{attrs:{"label":"左2"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","accept":".jpg,.png","auto-upload":false,"on-remove":(e) => {
              _vm.handleRemove(e, 'fileList2');
            },"on-change":(e) => {
              _vm.handleAvatarSuccess(e, '2');
            }}},[(_vm.fileList2)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.fileList2.pic}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-form-item',{attrs:{"label":"中1"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","accept":".jpg,.png","auto-upload":false,"on-remove":(e) => {
              _vm.handleRemove(e, 'fileList3');
            },"on-change":(e) => {
              _vm.handleAvatarSuccess(e, '3');
            }}},[(_vm.fileList3)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.fileList3.pic}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-form-item',{attrs:{"label":"中2"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","accept":".jpg,.png","auto-upload":false,"on-remove":(e) => {
              _vm.handleRemove(e, 'fileList4');
            },"on-change":(e) => {
              _vm.handleAvatarSuccess(e, '4');
            }}},[(_vm.fileList4)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.fileList4.pic}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-form-item',{attrs:{"label":"右大"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","accept":".jpg,.png","auto-upload":false,"on-remove":(e) => {
              _vm.handleRemove(e, 'fileList5');
            },"on-change":(e) => {
              _vm.handleAvatarSuccess(e, '5');
            }}},[(_vm.fileList5)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.fileList5.pic}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1)],1),_c('div',{staticStyle:{"height":"40px"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }