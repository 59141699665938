<template>
    <div class="menu">
        <el-menu @open="handleOpen" @close="handleClose" background-color="#545c64" text-color="#fff"
            active-text-color="#ffd04b" router :default-active="activeIndex">
            <el-submenu index="1">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>基本信息</span>
                </template>
                <el-menu-item index="/admin/gscy">公司产业</el-menu-item>
                <el-menu-item index="/admin/cpjs">产品介绍</el-menu-item>
                <el-menu-item index="/admin/zcjy">总裁寄语</el-menu-item>
                <el-menu-item index="/admin/gyqy">关于企业</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>新闻资讯</span>
                </template>
                <el-menu-item index="/admin/news">资讯管理</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>人才引进</span>
                </template>
                <el-menu-item index="/admin/invite">招聘管理</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>公开信息</span>
                </template>
                <el-menu-item index="/admin/profiles">信息列表</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
export default {
    name: 'adminMenu',
    data() {
        return {

        }
    },
    methods: {
        handleOpen() {
            // console.log(key, keyPath);
        },
        handleClose() {
            // console.log(v);
        }
    },
    computed:{
        activeIndex(){
            return this.$route.path
        }
    }
}
</script>
<style scoped>
.menu {
    width: 100%;
}

.menu>>>.el-submenu__title {
    width: 200px;
    box-sizing: border-box;
}

.menu>>>.el-menu-item {
    width: 200px;
    box-sizing: border-box;
}
</style>