<template>
    <router-view></router-view>
</template>
<script>
    export default {
        name: 'adminMain'
    }
</script>
<style scoped>
.main {
    width:  calc(100% -10px);
    height: calc(100% - 4px);
    overflow: auto;
    box-sizing: border-box;
}
</style>