<template>
  <div class="all">
    <Header></Header>
    <div class="part2">
      <div class="part2-text1">招聘信息</div>
      <div class="part2-text2">欢迎加入我们</div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 招聘信息</div>
    </div>
    <div class="content-item">
      <i class="el-icon-search search-icon" @click="getList"></i>
      <el-input
        @keyup.enter.native="getList"
        placeholder="请输入内容"
        v-model="searchText"
        class="input-with-select"
      >
      </el-input>
    </div>
    <div class="part4">
      <div class="part4-div1" v-for="item in inviteMsg" :key="item.id">
        <div class="part4-div1-text1">
          <span class="part4-div1-text1-span1">{{item.jobPost}}</span>
          <span class="part4-div1-text1-span2">{{item.salary}}</span>
        </div>
        <div class="part4-div1-text2">
          <div class="part4-div1-text2-div1">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">学历要求:</p>
              <p class="part4-div1-text2-div1-p2">工作地点:</p>
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">{{item.education}}</p>
              <p class="part4-div1-text2-div1-p4">{{item.address}}</p>
            </div>
          </div>
          <div class="part4-div1-text2-div2">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">工作经验:</p>
              <p class="part4-div1-text2-div1-p2">截止时间:</p>
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">{{item.experience}}</p>
              <p class="part4-div1-text2-div1-p4">{{item.deadline | formatDate}}</p>
            </div>
          </div>
          <div class="part4-div1-text2-div3">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">招聘人数:</p>
              <!-- <p class="part4-div1-text2-div1-p2">工作地点:</p> -->
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">{{item.num}}人</p>
              <!-- <p class="part4-div1-text2-div1-p4">河南郑州</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-item" style="height: 50px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <Nav></Nav>
  </div>
</template>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      searchText: "",
      navIndex: 1,
      currentPage: 1,
      total: 0,
      inviteMsg: [
        {
          id: "",
          deadline: "",
          education: "",
          experience: "",
          jobPost: "",
          address: "",
          num: "",
          salary: "",
        },
      ],
    };
  },
  methods: {
    getList() {
      this.searchText = this.searchText.trim()
      this.onLoadInvite()
    },
    handleSizeChange(size) {
      console.log(size);
    },
    handleCurrentChange(curr) {
      console.log(curr);
    },
    async onLoadInvite() {
      try {
        const { data: res } = await this.$http.get("inviteController/invites", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            jobPost: this.searchText,
          },
        });
        if (res.code === 0) {
          this.inviteMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date
        .toLocaleDateString("zh-CN", options)
        .replace(/\//g, "-")
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$1月$2日");
    },
  },
  created(){
    this.onLoadInvite()
  }
};
</script>
<style scoped>
.all {
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
}

.part2 {
  width: 1920px;
  height: 300px;
  background-image: url("../../src/assets/homepage/bg6.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  padding-top: 18px;
  margin-left: 906px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
}

.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 107px;
  /* border: solid 1px red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 15px;
}

.part4 {
  height: 955px;
  width: 1200px;
  /* border: solid 1px red; */
  margin: 0 auto;
}

.part4-div1:nth-child(2n) {
  margin-left: 40px;
}

.part4-div1 {
  width: 580px;
  height: 151px;
  background: #f8f8f8;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  margin-bottom: 40px;
}

.part4-div1-text1 {
  width: 580px;
  height: 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.part4-div1-text1-span1 {
  margin-left: 32px;
}

.part4-div1-text1-span2 {
  margin-right: 32px;
}

.part4-div1-text2 {
  height: 58px;
  width: 580px;
  margin-top: 21px;
}

.part4-div1-text2-div1 {
  display: inline-block;
  width: 134px;
  height: 58px;
  margin-left: 32px;
  vertical-align: top;
}

.part4-div1-text2-div2 {
  display: inline-block;
  width: 155px;
  height: 58px;
  margin-left: 40px;
}

.part4-div1-text2-div3 {
  display: inline-block;
  width: 155px;
  height: 58px;
  margin-left: 11px;
}

.part4-div1-text2-div1-p1 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 24px;
  margin: 0;
}

.part4-div1-text2-div1-p2 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 24px;
  margin-top: 10px;
}

.part4-div1-text2-div1-text1 {
  display: inline-block;
  vertical-align: top;
}

.part4-div1-text2-div1-text2 {
  display: inline-block;
  vertical-align: top;
}

.part4-div1-text2-div1-p3 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 24px;
  margin: 0;
  margin-left: 12px;
}

.part4-div1-text2-div1-p4 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 24px;
  margin-top: 10px;
  margin-left: 12px;
}

.part5 {
  width: 1200px;
  height: 582px;
  background: #f8f8f8;
  border: 1px solid red;
  margin: 0 auto;
}

.part5-text1 {
  width: 128px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  margin-top: 65px;
}

.part5-div2 {
  width: 1200px;
  height: 388px;
  border: 1px red solid;
  margin-top: 24px;
}

.part4-div2-div1 {
  width: 900px;
  height: 388px;
  background: #ffffff;
  border: solid 1px blueviolet;
  display: inline-block;
  vertical-align: top;
}

.part4-div2-div2 {
  background-image: url(../assets/homepage/蒙版（20）.png);
  display: inline-block;
  width: 284px;
  height: 388px;
  margin-left: 14px;
  vertical-align: top;
}

.part4-div2-div1-text1 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
  margin-top: 60px;
  display: inline-block;
}

.part4-div2-div1-text2 {
  display: inline-block;
  width: 280px;
  height: 40px;
  background: #f8f8f8;
  border: whitesmoke;
  margin-left: 12px;
}
.search-icon {
  position: absolute;
  right: 25px;
  top: 14px;
  z-index: 99999;
  font-size: 32px;
  color: #cdcdcd;
  cursor: pointer;
}

.content-item {
  width: 1200px;
  margin: 0px auto;
  margin-bottom: 25px;
  margin-top: -15px;
  position: relative;
}

.el-pagination {
  position: absolute;
  top: 0;
  right: 0;
}

.input-with-select {
  height: 60px;
}

.input-with-select >>> .el-input__inner {
  height: 100%;
  font-size: 16px;
  /* border: 1px solid #ccc; */
}
</style>
