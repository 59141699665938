<template>
  <div class="all">
    <div class="nav">
      <div class="nav_img">
        <img src="../../src/assets/homepage/logo(2).png" />
      </div>
      <div class="nav_ul">
        <ul>
          <li><router-link to="/"> 首页</router-link></li>
          <li><router-link to="/about">公司概况</router-link></li>
          <li><router-link to="/publicNews">公开信息</router-link></li>
          <li><router-link to="/another">公司新闻</router-link></li>
          <li><router-link to="/aboutProduct">产品展示</router-link></li>
          <li><router-link to="/other">联系我们</router-link></li>
          <li><router-link to="/join">招聘信息</router-link></li>
        </ul>
      </div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 新闻详细</div>
    </div>
    <div class="part4">
      <div class="part4-text1">
        {{newMsg.title}}
      </div>
      <div class="part4-div1">
        <div class="timeDiv">
          <img src="../assets/homepage/矩形9.png" class="part4-div1-img" />
          <div class="part4-div1-text1">发布时间：{{ newMsg.createdTime | formatDate}}</div>
        </div>
        <!-- <div>
          <img src="../assets/homepage/矩形10.png" class="part4-div1-img" />
          <div class="part4-div1-text1">发布时间：2019-11-15</div>
        </div> -->
      </div>
      <div class="xian"></div>
      <!-- <div class="part4-div2">
        <div class="part4-div2-text1">
          “数字经济是全球未来的发展方向，创新是亚太经济腾飞的翅膀。”数字技术已成为促进经济发展、提高经济各领域竞争力、打造新兴市场并保障全面可持续增长的关键因素之一。接受本报记者采访的国际人士表示，推动数字经济进一步发展，加强数字化领域全面合作，将为促进包容性增长和可持续发展带来新的机遇。
        </div>
        <div class="part4-div2-text2">
          扩大覆盖范围，数字经济保持强劲发展势头
        </div>
        <div class="part4-div2-text1">
          11月23日发布的《中国互联网发展报告2020》指出
          ，2019年，中国数字经济规模达35.8万亿元，占国内生产总值比重达36.2%，中国数字经济总量规模和增长速度位居世界前列。近年来，中国不断扩大数字基础设施覆盖范围，培育专业化的数字人才队伍，数字经济快速发展。
        </div>
        <div class="part4-div2-text1">
          “中国拥有全球最大的互联网人口规模、最活跃的数字经济领域投资和最具活力的创业生态系统，中国将继续作为全球数字经济的领先力量。”中欧数字协会主席路易吉·甘巴尔代拉提及一系列数字：中国网民规模逾9亿；电子商务交易规模连续多年位居全球电子商务市场首位，移动支付普及率也位居世界第一；在全球知名数据智库CB
          Insights发布的全球500家独角兽企业排行榜上，中国企业有119家，排名第二。“这些数字无可争议地表明，中国数字经济保持强劲的发展势头。”
        </div>
        <div class="part4-div2-text1">
          中国不断推进数字基础设施建设给甘巴尔代拉留下深刻印象。他特别提到，在疫情防控期间，中国教育部开通国家中小学网络云平台，供全国近1.8亿中小学生免费使用，相关在线教育服务覆盖偏远和贫困地区。同时，还实施教师在线教学能力提升行动，组织多方力量制作教师在线教学能力提升培训资源包，帮助那些不习惯在线教学的教师提高数字技能。“如今，中国经济正在从高速增长阶段转向高质量发展阶段，数字经济必将在其中发挥重要引擎作用。”
        </div>
        <div class="part4-div2-text1">
          中国互联网络信息中心9月底发布的第四十六次《中国互联网络发展状况统计报告》显示，中国城乡数字鸿沟正在显著缩小。中国城乡地区互联网普及率差异为24.1%，2017年以来首次缩小到30%以内，全国贫困村通光纤比例从2017年的不足70%提升至98%。谢栋铭表示，中国政府重视在推动数字经济发展的同时关心困难群体。对世界各国的政策制定者来说，应确保民众能普遍享受到数字经济发展带来的红利。（记者
          俞懿春 方莹馨 景玥 林芮）
        </div>
      </div>
      <div class="part4-div3">
        <img src="../assets/homepage/位图30.png" alt="" />
      </div> -->
      <div v-html="newMsg.content" id="div_content">

      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<script>
import Nav from "../components/nav1/bottomnav.vue";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      newMsg: {
        id: "",
        title: "",
        content: "",
        type: "",
        createdTime: "",
        updateTime: "",
        createdUser: "",
        updateUser: "",
        author: "",
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      if (
        this.$route.query.id !== undefined ||
        this.$route.query.id !== null
      ) {
        const { data: res } = await this.$http.get(
          "newController/findNewById/" + this.$route.query.id
        );
        if (res.code == 0) {
          this.newMsg = res.data;
        }
      }
    },
  },
  filters:{
    formatDate(value) {
      const date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date
        .toLocaleDateString("zh-CN", options)
        .replace(/\//g, "-")
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$1月$2日");
    },
  }
};
</script>
<style scoped>
.all {
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
  list-style-type: none;
}

.nav_ul li {
  display: inline-block; /* 将列表项横向排列 */
  margin-right: 45px;
}

a {
  text-decoration: none;
}

.part3 {
  width: 1920px;
  height: 46px;
  background: #f8f8f8;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  padding-top: 15px;
}

.part4 {
  width: 1200px;
  /* height: 1697px; */
  margin-bottom: 100px;
  background: #ffffff;
  margin: 100px auto;
  /* border: solid 1px red; */
}

.part4-text1 {
  margin-top: 60px;
  margin-left: 198px;
  width: 804px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  text-align: center;
}

.part4-div1 {
  display: flex;
  justify-content: space-between;
  width: 372px;
  height: 24px;
  /* border: solid red 1px; */
  margin: 8px auto 0 auto;
}

.part4-div1-img {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
}
.timeDiv{
  margin: auto;
  margin-top: 20px;
}

.part4-div1-text1 {
  /* margin-left: 6px; */
  width: 152px;
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  display: inline-block;
  vertical-align: top;
}

.xian {
  width: 1200px;
  height: 4px;
  border: 1px solid #eaeaea;
  margin: 40px auto 0 auto;
  background: white;
}

#div_content>p>img{
  width: 80%;
  margin: auto;
}

.part4-div2 {
  width: 1000px;
  height: 618px;
  margin: 40px auto 0 auto;
  /* border: red 1px solid; */
}

.part4-div2-text1 {
  margin-bottom: 31px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 32px;
}

.part4-div2-text2 {
  margin-bottom: 31px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 32px;
}

.part4-div3 {
  width: 804px;
  height: 453px;
  margin: 37px auto 0 auto;
}

.part4-div4 {
  width: 1200px;
  height: 68px;
  background: #f8f8f8;
  margin: 0 auto;
  /* border: steelblue 1px solid; */
  margin-top: 37px;
}

.part4-div4-img {
  margin-top: 26px;
  display: inline-block;
  vertical-align: top;
}

.part4-div4-text1 {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 20px;
  margin-top: 24px;
  margin-left: 12px;
}

.part4-div4-div {
  width: 1120px;
  height: 68px;
  padding: 24px auto 24px auto;
  /* border: 1px red solid; */
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.part4-div4-text2 {
  width: 295px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  text-align: right;
  margin-top: 24px;
  margin-right: 12px;
  display: inline-block;
  vertical-align: top;
}

.part4-div5 {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 33px;
  margin: 40px auto 0 auto;
  /* border: solid 1px red; */
}

.part4-div5-text1 {
  width: 96px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
  display: inline-block;
  vertical-align: top;
}

.part4-div5-text2 {
  width: 260px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  text-align: right;
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}

.part4-div6 {
  width: 1200px;
  height: 184px;
  margin: 32px auto 0 auto;
  /* border: solid 1px red; */
  display: flex;
  justify-content: space-between;
}

.part4-div6-div1 {
  width: 588px;
  height: 184px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}

.part4-div6-div2 {
  width: 588px;
  height: 184px;
  background: #f8f8f8;
}

.part4-div6-div1-text1 {
  margin-top: 32px;
  margin-left: 20px;
  width: 547px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.part4-div6-div1-text2 {
  width: 506px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
  margin: 10px 62px 10px 20px;
}
.part4-div6-div1-text3 {
  width: 507px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  margin-left: 20px;
}
</style>