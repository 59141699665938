import { render, staticRenderFns } from "./gyqy.vue?vue&type=template&id=03c8909c&scoped=true&"
import script from "./gyqy.vue?vue&type=script&lang=js&"
export * from "./gyqy.vue?vue&type=script&lang=js&"
import style0 from "./gyqy.vue?vue&type=style&index=0&id=03c8909c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c8909c",
  null
  
)

export default component.exports