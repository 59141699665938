<template>
    <div class="header">
        <div class="header-left">当前位置：{{ info }}</div>
        <div class="header-right">后台管理系统</div>
    </div>
</template>
<script>
    export default {
        name: 'adminHeader',
        data(){
            return {}
        },
        computed: {
            info (){
                return this.$route.meta.title
            }
        },
        mounted() {

        }
    }
</script>
<style scoped>
    .header {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .header-left {
        width: 50%;
    }
    .header-right {
        width: 50%;
    }

</style>