<template>
  <div class="admin">
    <el-container>
      <el-aside width="200px">
        <div class="logo"></div>
        <Menu></Menu>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <Main></Main>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Menu from "./menu.vue";
import Main from "./main.vue";
import Header from "./header";
export default {
  name: "adminIndex",
  components: {
    Menu,
    Main,
    Header,
  },
};
</script>
<style scoped>
.admin {
  width: 100%;
  height: 100%;
}
.el-container {
  width: 100%;
  height: 100%;
}
.el-header {
  background-color: #607080;
  line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  height: 100%;
}

.logo {
  width: 160px;
  height: 80px;
  margin: 20px auto;
  background-image: url("../../assets/homepage/logo(2).png");
  background-size: 100% 100%;
}
</style>
