<template>
  <div class="nav">
    <div class="nav_img">
      <img src="../../assets/homepage/logo(2).png" />
    </div>
    <div >
      <ul class="index_nav_ul" >
        <li><router-link to="/"> 首页</router-link></li>
        <li><router-link to="/about">公司概况</router-link></li>
        <li><router-link to="/publicNews">公开信息</router-link></li>
        <li><router-link to="/another">公司新闻</router-link></li>
        <li><router-link to="/aboutProduct">产品展示</router-link></li>
        <li><router-link to="/other">联系我们</router-link></li>
        <li><router-link to="/join">招聘信息</router-link></li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
/* .ddd:hover .dropdown_content {
  display: block;
  z-index: 999;
} */
/* .divsss p {
  text-align: center;
  line-height: 40px;
  color: #000;
} */
/* .divsss {
  width: 140px;
  height: 48px;
  background: #ffffff;
 

} */
/* .dropdown_content {
  display: none;
  position: absolute;
  width: 140px;
  height: 306px;
  background: #ffffff;
  top: 71px;
  left: 875px;
} */
.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.index_nav_ul {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
  list-style-type: none;
}

.index_nav_ul li {
  display: inline-block; /* 将列表项横向排列 */
  margin-right: 45px;
}
a {
  text-decoration: none;
}
</style>
<script>
export default {
  name: "Header",
};
</script>


