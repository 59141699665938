<template>
  <el-card class="gscy">
    <el-card class="form-card">
      <el-form
        :model="ruleForm"
        :inline="true"
        ref="ruleForm"
        label-width="100px"
        class="el-form-s"
      >
        <el-form-item label="左1">
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.png"
            :auto-upload="false"
            :on-remove="
              (e) => {
                handleRemove(e, 'fileList1');
              }
            "
            :on-change="
              (e) => {
                handleAvatarSuccess(e, '1');
              }
            "
          >
            <img v-if="fileList1" :src="fileList1.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="左2">
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.png"
            :auto-upload="false"
            :on-remove="
              (e) => {
                handleRemove(e, 'fileList2');
              }
            "
            :on-change="
              (e) => {
                handleAvatarSuccess(e, '2');
              }
            "
          >
            <img v-if="fileList2" :src="fileList2.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="中1">
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.png"
            :auto-upload="false"
            :on-remove="
              (e) => {
                handleRemove(e, 'fileList3');
              }
            "
            :on-change="
              (e) => {
                handleAvatarSuccess(e, '3');
              }
            "
          >
            <img v-if="fileList3" :src="fileList3.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="中2">
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.png"
            :auto-upload="false"
            :on-remove="
              (e) => {
                handleRemove(e, 'fileList4');
              }
            "
            :on-change="
              (e) => {
                handleAvatarSuccess(e, '4');
              }
            "
          >
            <img v-if="fileList4" :src="fileList4.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="右大">
          <el-upload
            class="avatar-uploader"
            action=""
            accept=".jpg,.png"
            :auto-upload="false"
            :on-remove="
              (e) => {
                handleRemove(e, 'fileList5');
              }
            "
            :on-change="
              (e) => {
                handleAvatarSuccess(e, '5');
              }
            "
          >
            <img v-if="fileList5" :src="fileList5.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div style="height: 40px">
        <!-- <el-button style="float: right" @click="submit">提交</el-button> -->
      </div>
    </el-card>
  </el-card>
</template>
<script>
// import { changeFileIntoBase64 } from "@/utils/index";
import global_msg from "../../../../global";
export default {
  name: "gscy",
  data() {
    return {
      type: 4,
      tempId: "",
      ruleForm: {
        fileList1: "",
        fileList2: [],
        fileList3: [],
        fileList4: [],
        fileList5: [],
      },
      fileList1: {
        id: "",
        pic: '',
        relation: "",
        sort: "",
        type: "",
      },
      fileList2: {
        id: "",
        pic: '',
        relation: "",
        sort: "",
        type: "",
      },
      fileList3: {
        id: "",
        pic: '',
        relation: "",
        sort: "",
        type: "",
      },
      fileList4: {
        id: "",
        pic: '',
        relation: "",
        sort: "",
        type: "",
      },
      fileList5: {
        id: "",
        pic: '',
        relation: "",
        sort: "",
        type: "",
      },
    };
  },
  created() {
    //公司产业图片类型type=4；
    this.onLoadImage();
  },
  methods: {
    async handleAvatarSuccess(file, key) {
      //   this[key] = URL.createObjectURL(file.raw);
      //   this.ruleForm[key] = await changeFileIntoBase64(file.raw);
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("type", this.type);
      formData.append("sort", key);
      try {
        const { data: res } = await this.$http.post(
          "admin/uploadPictures",
          formData
        );
        if (res.code === 0) {
          console.log(key);
          switch (key) {
            case "1":
              this.fileList1.id = res.data.id;
              this.fileList1.pic = global_msg.imgPath + res.data.pic;
              break;
            case "2":
              this.fileList2.id = res.data.id;
              this.fileList2.pic = global_msg.imgPath + res.data.pic;
              break;
            case "3":
              this.fileList3.id = res.data.id;
              this.fileList3.pic = global_msg.imgPath + res.data.pic;
              break;
            case "4":
              this.fileList4.id = res.data.id;
              this.fileList4.pic = global_msg.imgPath + res.data.pic;
              break;
            case "5":
              this.fileList5.id = res.data.id;
              this.fileList5.pic = global_msg.imgPath + res.data.pic;
              break;
          }
          console.log(res.data.pic);
          console.log(this.fileList5);
        }
      } catch (error) {
        console.error(error);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async handleRemove(e, key) {
      console.log(e);
      switch (key) {
        case "fileList1":
          this.tempId = this.fileList1.id;
          break;
        case "fileList2":
          this.tempId = this.fileList2.id;
          break;
        case "fileList3":
          this.tempId = this.fileList3.id;
          break;
        case "fileList4":
          this.tempId = this.fileList4.id;
          break;
        case "fileList5":
          this.tempId = this.fileList5.id;
          break;
      }
      let formData = new FormData();
      formData.append("id", this.tempId);
      const { data: res } = await this.$http.post("admin/deletePic", formData);
      if (res.code === 0) {
        switch (key) {
          case "fileList1":
            this.fileList1.id = "";
            this.fileList1.pic = ''
            break;
          case "fileList2":
            this.fileList2.id = "";
            this.fileList2.pic = ''
            break;
          case "fileList3":
            this.fileList3.id = "";
            this.fileList3.pic = '';
            break;
          case "fileList4":
            this.fileList4.id = "";
            this.fileList4.pic = ''
            break;
          case "fileList5":
            this.fileList5.id = "";
            this.fileList5.pic = ''
            break;
        }
        this.$message.success("删除成功");
      }
    },
    submit() {
      console.log(this.ruleForm);
    },
    async onLoadImage() {
      try {
        const { data: res } = await this.$http.get("newController/pictures", {
          params: { type: 4 },
        });
        if (res.code === 0) {
          res.data.forEach((picObj) => {
            switch (picObj.sort) {
              case 1:
                this.fileList1.id = picObj.id;
                this.fileList1.pic = global_msg.imgPath + picObj.pic;
                break;
              case 2:
                this.fileList2.id = picObj.id;
                this.fileList2.pic = global_msg.imgPath + picObj.pic;
                break;
              case 3:
                this.fileList3.id = picObj.id;
                this.fileList3.pic = global_msg.imgPath + picObj.pic;
                break;
              case 4:
                this.fileList4.id = picObj.id;
                this.fileList4.pic = global_msg.imgPath + picObj.pic;
                break;
              case 5:
                this.fileList5.id = picObj.id;
                this.fileList5.pic = global_msg.imgPath + picObj.pic;
                break;
            }
          });
          console.log(this.fileList1.pic);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.gscy {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 1200px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader >>> .el-upload-dragger {
  width: 178px;
  height: 178px;
}

#deleteBtn {
  width: 100%;
  height: 30px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hide_box >>> .el-upload--picture-card {
  display: none !important;
}
</style>
