<template>
  <el-card class="zcjy">
    <el-card class="form-card">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="el-form-s"
      >
        <el-form-item label="岗位名称" prop="jobPost">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.jobPost"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历要求" prop="education">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.education"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作经验">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.experience"
          ></el-input>
        </el-form-item>
        <el-form-item label="招聘人数">
          <el-input-number
            v-model="ruleForm.num"
            :min="1"
            :max="10000000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="工作地点">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.address"
          ></el-input>
        </el-form-item>
        <el-form-item label="工资范围">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.salary"
          ></el-input>
        </el-form-item>
        <el-form-item label="截止时间">
          <el-date-picker
            v-model="ruleForm.deadline"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="height: 40px">
        <el-button style="float: right" @click="submit">提交</el-button>
      </div>
    </el-card>
  </el-card>
</template>
<script>
export default {
  name: "editInvite",
  data() {
    return {
      ruleForm: {
        id: '',
        deadline: '',
        education: '',
        experience: '',
        jobPost: '',
        address: '',
        num: '',
        salary: '',
      },
    };
  },
  methods: {
    submit() {
      try {
        this.$refs["ruleForm"].validate(async (valid) => {
          if (valid) {
            console.log(this.ruleForm);
            const data = new Date(this.ruleForm.deadline);
            const formattedDeadline = data.toISOString().split("T")[0];
            const params = new FormData();
            if (this.ruleForm.id === undefined) {
              params.append("id", null);
            } else {
              params.append("id", this.ruleForm.id);
            }
            params.append("deadline", formattedDeadline);
            params.append("education", this.ruleForm.education);
            params.append("experience", this.ruleForm.experience);
            params.append("jobPost", this.ruleForm.jobPost);
            params.append("address", this.ruleForm.address);
            params.append("num", this.ruleForm.num);
            params.append("salary", this.ruleForm.salary);
            const { data: res } = await this.$http.post(
              "admin/addInvite",
              params
            );
            if (res.code === 0) {
              this.$message.success("编辑成功！");
              this.$router.push({ name: "invite" });
            } else {
              this.$message.error(`${res.msg}`);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadById(id) {
      try {
        const { data: res } = await this.$http.get("inviteController/invite", {
          params: {
            id: id,
          },
        });
        if (res.code === 0) {
          this.ruleForm = res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00`;
    },
  },
  mounted() {
    console.log(this.$route.query.id);
    if (this.$route.query.id !== null && this.$route.query.id  !== undefined ) {
      this.onLoadById(this.$route.query.id);
    }
  },
};
</script>
<style scoped>
.zcjy {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 800px;
}
</style>