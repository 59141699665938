<template>
  <el-card class="invite">
    <el-card class="form-card">
      <div>
        <input
          type="file"
          name="pdf"
          accept="application/pdf"
          @change="onchangePdfFun"
          class="header-upload-btn user-header-com"
        />
        <el-button type="primary" @click="getList">刷新</el-button>
        <div class="nav">
          <el-input
            style="margin-left: 15px; width: 400px"
            v-model="searchText"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="profileMsgs" style="width: 100%">
        <el-table-column prop="createdTime" label="创建日期" width="180">
          <template slot-scope="scope">{{
            scope.row.createdTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column
          prop="fileName"
          label="文件名"
          width="400"
        ></el-table-column>
        <el-table-column prop="fileExtName" label="文件后缀"> </el-table-column>
        <el-table-column prop="fileSize" label="文件大小"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="160" class="caozuo">
          <template slot-scope="scope">
            <!-- <el-button @click="handleClick(scope.row)" type="text"
              >编辑</el-button
            > -->
            <!-- <el-button type="text" >编辑</el-button> -->
            <el-button type="text" @click="deleteProfile(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </el-card>
</template>
<script>
export default {
  name: "profiles",
  data() {
    return {
      searchText: "",
      navIndex: 1,
      currentPage: 1,
      total: 1,
      pageSize: 10,
      // imgPath: global_msg.imgPath,
      profileMsgs: [
        {
          id: "",
          fileName: "",
          fileExtName: "",
          createdTime: "",
          filePath: "",
          fileSize: "",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(size) {
      console.log(size);
      this.pageSize = size;
      this.getList();
    },
    async handleCurrentChange(currentPage) {
      //   this.currentPage = currentPage;
      //   this.getList();
      console.log(currentPage);
    },
    // viewPDF(profile) {
    //   var url =
    //     global_msg.backPath + "profileController/previewCheck/" + profile.id;
    //   window.open(url, "_blank", "noopener");
    // },
    async getList() {
      this.searchText.trim();
      try {
        const { data: res } = await this.$http.get(
          "profileController/profiles",
          {
            params: {
              pageSize: this.pageSize,
              currentPage: this.currentPage,
              name: this.searchText,
            },
          }
        );
        if (res.code === 0) {
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
          this.profileMsgs = res.list;
          console.log(this.profileMsgs);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteProfile(id) {
      try {
        const { data: res } = await this.$http.get("admin/deleteProfile/" + id);
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.getList();
        } else {
          this.$message.error("删除失败！");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onchangePdfFun(event) {
      const file = event.target.files[0]; // 获取选择的文件对象
      let formdata = new FormData();
      formdata.append("file", file);
      try {
        const { data: res } = await this.$http.post(
          "admin/uploadProfile",
          formdata
        );
        if (res.code === 0) {
          this.$message.success("上传成功！");
          this.getList();
        } else {
          this.$message.error("上传失败！");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    removeTags: function (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
};
</script>

<style scoped>
.invite {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 100%;
}
.nav {
  display: inline-block;
  position: relative;
}
.caozuo {
  padding: 11px 0;
}
</style>
