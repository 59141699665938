<template>
  <el-card class="news">
    <el-card class="form-card">
      <div>
        <el-button type="primary" @click="addNew">新增</el-button>
        <el-button type="primary" @click="refresh()">刷新</el-button>
        <div class="nav">
          <el-input
            style="margin-left: 15px; width: 400px"
            v-model="searchText"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearchNews()"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="newMsg" style="width: 100%">
        <el-table-column prop="createdTime" label="发布日期" width="180">
          <template slot-scope="scope">{{
            scope.row.createdTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="300"
          ><template slot-scope="scope">
            <span class="content" :ref="'isshow' + scope.row.index">
              {{ scope.row.title }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容">
          <template slot-scope="scope">
            <span class="content" :ref="'isshow' + scope.row.index">
              {{ scope.row.content | removeTags}}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text"
              >编辑</el-button
            >
            <!-- <el-button type="text">编辑</el-button> -->
            <el-button type="text" @click="deleteNew(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </el-card>
</template>
<script>
export default {
  name: "news",
  data() {
    return {
      tableData: [
      ],
      newMsg: [
        {
          id: "",
          title: "",
          content: "",
          type: "",
          createdTime: "",
          updateTime: "",
          createdUser: "",
          updateUser: "",
          author: "",
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchText: "",
    };
  },
  created() {
    this.onLoadNewList();
  },
  methods: {
    handleClick(news) {
      this.$router.push({ name : "editNews", query: { id : news.id }, meta: {keepAlive: true} })
    },
    handleSizeChange() {},
    async handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            type: 0,
            title: this.searchText,
          },
        });
        if (res.code === 0) {
          this.newMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearchNews() {
      this.searchText.trim();
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            type: 0,
            title: this.searchText,
          },
        });
        if (res.code === 0) {
          this.newMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
    addNew() {
      this.$router.push({ name: "editNews" });
    },
    async refresh() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            type: 0,
            title: this.searchText,
          },
        });
        if (res.code === 0) {
          this.newMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteNew(id){
      const { data: res } = await this.$http.post("admin/deleteNew/" + id);
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.refresh()
      }
    },
    async onLoadNewList() {
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            type: 0,
            title: this.searchText,
          },
        });
        if (res.code === 0) {
          this.newMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    removeTags: function(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }
  },
};
</script>
<style scoped>
.news {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 100%;
}
.nav {
  display: inline-block;
  position: relative;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>
