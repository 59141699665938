<template>
  <el-card class="zcjy">
    <el-card class="form-card">
      <el-form
      :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="el-form-s"
      >
        <el-form-item label="总裁寄语">
          <el-input
            placeholder="请输入内容"
            :autosize="{ minRows: 20, maxRows: 50 }"
            type="textarea"
            v-model="newMsg.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="总裁名字">
          <el-input placeholder="请输入内容" v-model="newMsg.author"></el-input>
        </el-form-item>
      </el-form>
      <div style="height: 40px">
        <el-button style="float: right" @click="submit">提交</el-button>
      </div>
    </el-card>
  </el-card>
</template>
<script>
export default {
  name: "zcjy",
  data() {
    return {
      ruleForm: {
        text: "",
      },
      newMsg: {
        id: "",
        title: "总裁寄语",
        content: "",
        type: 4,
        createdTime: "",
        updateTime: "",
        createdUser: "",
        updateUser: "",
        author: "",
      },
    };
  },
  created() {
    this.onLoadText();
  },
  methods: {
    async submit() {
      try {
        const { data: res } = await this.$http.post(
          "admin/addText",
          this.newMsg
        );
        if (res.code === 0) {
          this.$message.success("修改成功！");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadText() {
            try {
                const { data: res } = await this.$http.get("newController/news", {
                    params: { pageSize: 1, currentPage: 1, type: 4 },
                });
                if (res.code === 0) {
                    this.ObjectList = res.list;
                    if (res.list[0] !== null) {
                        this.ruleForm.text = res.list[0].content;
                        this.newMsg = res.list[0];
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
  },
};
</script>
<style scoped>
.zcjy {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 1200px;
}
</style>
