<template>
  <el-card class="addNew">
    <el-form
      :model="addNewForm"
      :rules="addNewRules"
      ref="addNewForm"
      label-width="80px"
      size="small"
      status-icon
    >
      <el-form-item label="资讯标题" prop="new_title">
        <el-input v-model="addNewForm.new_title"></el-input>
      </el-form-item>

      <el-form-item label="封面" style="display: inline-block">
        <div class="user-header">
          <input
            type="file"
            name="image"
            accept="image/*"
            @change="onchangeImgFun"
            class="header-upload-btn user-header-com"
          />
          <img alt="" :src="imgStr" class="user-header-img user-header-com" />
          <p class="tip">
            (点击选取图片,最大5mb) <span class="error">{{ errorStr }}</span>
          </p>
        </div>
        <el-button type="primary" @click="sumbitAddNew('addNewForm')"
          >立即提交</el-button
        >
      </el-form-item>
    </el-form>
    <div id="wangEditor"></div>
  </el-card>
</template>
<script>
import wangEditor from "wangeditor";
import tool from "@/utils/tool";
import global_msg from "../../../../global";
export default {
  name: "addNew",
  data() {
    return {
      addNewForm: {
        new_title: "",
        new_author: "",
        new_content: "",
        new_image: "",
      },
      imgStr: "", //头像预览
      imgSize: "",
      targetImg: "",
      errorStr: "",
      headPictureId:'',
      editor: null,
      editorData: "",
      pictureList: [],
      addNewRules: {
        new_title: [
          { required: true, message: "请输入资讯标题", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    // 获取封面及预览
    async onchangeImgFun(e) {
      var file = e.target.files[0];
      // console.log(file)
      this.targetImg = e.target.files[0];
      // 获取图片的大小，做大小限制有用
      this.imgSize = file.size;
      console.log(this.imgSize);
      var _this = this; // this指向问题，所以在外面先定义
      // 比如上传头像限制5M大小，这里获取的大小单位是b
      if (_this.imgSize <= 5 * 1024 * 1024) {
        // 合格
        _this.errorStr = "";
        console.log("大小合适");
        // 开始渲染选择的图片
        // 本地路径方法 1
        // this.imgStr = window.URL.createObjectURL(e.target.files[0])
        // console.log(window.URL.createObjectURL(e.target.files[0])) // 获取当前文件的信息
        // base64方法 2
        var reader = new FileReader();
        reader.readAsDataURL(file); // 读出 base64
        reader.onloadend = function () {
          // 图片的 base64 格式, 可以直接当成 img 的 src 属性值
          var dataURL = reader.result;
          // console.log(dataURL)
          _this.imgStr = dataURL;
          //   // 下面逻辑处理
        };
        this.uploadHeadTo(file);
      } else {
        console.log("大小不合适");
        _this.errorStr = "图片大小超出范围";
      }
    },

    // 上传封面到服务器
    async uploadHeadTo(file) {
      const me = this;
      let formdata = new FormData();
      formdata.append("file", file);
      formdata.append("sort", 0);
      formdata.append("type", 0);
      const { data: res } = await me.$http.post(
        "admin/uploadPictures",
        formdata
      );
      if (res.code == 0) {
        me.pictureList.push(res.data);
        me.headPictureId = res.data.id;
        console.log(me.headPictureId)
      }
      me.addNewForm.new_image = global_msg.imgPath + res.data.pic;
    },
    async addNews() {
      const news = {
        id : this.$route.query.id === undefined ? null : this.$route.query.id ,
        title: this.addNewForm.new_title,
        content: this.addNewForm.new_content,
        type: 0,
        pictureList: this.pictureList,
      };
      console.log(news);
      const { data: res } = await this.$http.post("admin/addNew?headPictureId="+this.headPictureId, news);
      if (res.code === 0) {
        this.$message.success("修改成功");
        this.$router.push({ name: "news" });
        
      } else {
        this.$message.error(res.msg);
      }
    },
    // 提交
    sumbitAddNew(formName) {
        const me = this;
        me.$refs[formName].validate(async (valid) => {
          if (valid && me.errorStr == "") {
            if (me.$route.params.newInfo) {
              if (me.targetImg) {
                // await me.uploadHeadTo(me.targetImg);
                me.addNews();
              }
            } else {
              if (me.targetImg) {
                // await me.uploadHeadTo(me.targetImg);
                me.addNews();
              } else {
                me.addNews();
              }
            }
          } else {
            // console.log('error submit!!');
            this.$message.error("请填写必要项");
            return false;
          }
        });
    },
    async update() {},
    async onLoad() {
      if (
        this.$route.query.id !== undefined &&
        this.$route.query.id !== null
      ) {
        const { data: res } = await this.$http.get(
          "newController/findNewById/" + this.$route.query.id
        );
        if (res.code == 0) {
          this.addNewForm.new_title = res.data.title;
          this.addNewForm.new_content = res.data.content;
          this.pictureList = res.data.pictureList;
          this.editor.txt.html(`${this.addNewForm.new_content}`);
          if (res.data.pictureList !== null) {
            this.pictureList.forEach((picture) => {
              if (picture.sort === 0) {
                this.addNewForm.new_image = global_msg.imgPath + picture.pic;
                this.imgStr = global_msg.imgPath + picture.pic;
                this.headPictureId = picture.id;
              }
            });
          }
        }
      }
    },
  },
  mounted() {
    const me = this;
    // // console.log(this.$route.query);
    // if(this.$route.params.newInfo){
    //     me.addNewForm=this.$route.params.newInfo
    //     me.imgStr=me.addNewForm.new_image
    //     // console.log(this.$route.params.newInfo);
    // }else{
    //     const user= tool.data.get('userInfo')
    //     me.addNewForm.new_author=user.id
    // }

    const editor = new wangEditor(`#wangEditor`);

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      // console.log(newHtml);
      const a = tool.utf16toEntities(newHtml);
      if (a) {
        this.addNewForm.new_content = a;
        console.log(this.addNewForm.new_content);
      }
    };
    // 设置编辑区域高度为 500px
    editor.config.height = 500;
    // 上传图片
    editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      for (let i = 0; i < resultFiles.length; i++) {
        const formData = new FormData();
        formData.append("file", resultFiles[i]);
        formData.append("type", 0);
        formData.append("sort", i + 1);
        const { data: res } = await me.$http.post(
          "admin/uploadPictures",
          formData
        );
        if (res.code === 0) {
          me.pictureList.push(res.data);
          insertImgFn(global_msg.imgPath + res.data.pic);
        }
      }
      //   const formData = new FormData();
      // for(let i=0;i<resultFiles.length;i++){
      //     formData.append('file',resultFiles[i],resultFiles[i].name)
      // }

      // const result = await me.$http.upload.uploadImages.post(formData)

      // if(result.status==200){
      //     // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      //     // 上传图片，返回结果，将图片插入到编辑器中
      //     for(let j=0;j<result.message.length;j++){
      //         insertImgFn(result.message[j].path)
      //     }

      // }
    };
    editor.config.placeholder = "请输入资讯内容";
    // 创建编辑器
    editor.create();
    editor.txt.html(`${me.addNewForm.new_content}`);

    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>
<style scoped>
.addNew {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 1200px;
}

.user-header {
  position: relative;
  display: inline-block;
}
.user-header-com {
  width: 80px;
  height: 80px;
  display: inline-block;
}
.header-upload-btn {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  /* 通过定位把input放在img标签上面，通过不透明度隐藏 */
}
.tip {
  font-size: 12px;
  color: #666;
  display: inline-block;
  margin-left: 0px;
  margin-right: 20px;
}
/* error是用于错误提示 */
.error {
  font-size: 12px;
  color: tomato;
  margin-left: 10px;
}
/* 头像上传结束 */
</style>
