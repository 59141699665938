<template>
  <el-card class="invite">
    <el-card class="form-card">
      <div>
        <el-button type="primary" @click="addNew">新增</el-button>
        <el-button type="primary" @click="refresh">刷新</el-button>
        <div class="nav">
          <el-input
            style="margin-left: 15px; width: 400px"
            v-model="searchText"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleCurrentChange"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="inviteMsg" style="width: 100%">
        <el-table-column
          prop="deadline"
          label="截至日期"
          width="180"
          :formatter="formatDate"
        ></el-table-column>
        <el-table-column
          prop="jobPost"
          label="岗位"
          width="180"
        ></el-table-column>
        <el-table-column prop="education" label="学历"> </el-table-column>
        <el-table-column prop="experience" label="经验"> </el-table-column>
        <el-table-column prop="salary" label="工资"> </el-table-column>
        <el-table-column prop="num" label="招聘人数"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="160" class="caozuo">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text"
              >编辑</el-button
            >
            <!-- <el-button type="text" >编辑</el-button> -->
            <el-button type="text" @click="deleteInvite(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </el-card>
</template>
<script>
export default {
  name: "invite",
  data() {
    return {
      tableData: [
      ],
      inviteMsg: [
        {
          id: "",
          deadline: "",
          education: "",
          experience: "",
          jobPost: "",
          address: "",
          num: "",
          salary: "",
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchText: "",
    };
  },
  created() {
    this.onLoadNewList();
  },
  methods: {
    handleClick(invite) {
      this.$router.push({
        name: "editInvite",
        query: { id: invite.id },
        meta: { keepAlive: true },
      });
    },
    handleSizeChange() {},
    async handleCurrentChange() {
      try {
        const { data: res } = await this.$http.get("inviteController/invites", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            jobPost: this.searchText,
          },
        });
        if (res.code === 0) {
          this.inviteMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteInvite(id) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        const { data: res } = await this.$http.post(
          "admin/deleteInvite",
          formData
        );
        if (res.code === 0) {
          this.refresh();
          this.$message.success("删除成功！");
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(row) {
      const date = new Date(row.deadline);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    addNew() {
      this.$router.push({ name: "editInvite" });
    },
    submit() {
      console.log(this.ruleForm);
    },
    async onLoadNewList() {
      try {
        const { data: res } = await this.$http.get("inviteController/invites", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            jobPost: this.searchText,
          },
        });
        if (res.code === 0) {
          this.inviteMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async refresh() {
      try {
        const { data: res } = await this.$http.get("inviteController/invites", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            jobPost: this.searchText,
          },
        });
        if (res.code === 0) {
          this.inviteMsg = res.list;
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.invite {
  width: calc(100% - 5px);
  /* height: 100%; */
}
.form-card {
  width: 100%;
}
.nav {
  display: inline-block;
  position: relative;
}
.caozuo {
  padding: 11px 0;
}
</style>
