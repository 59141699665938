<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">公司新闻</div>
        <div class="part2-text2">更多最新精彩资讯，感受企业魅力</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 >  公司新闻</div>
      </div>
      <div class="part4">
        <div
          :class="navIndex == 1 ? 'nav-bg-color part4-div1' : 'part4-div1'"
          @click="changeNav(1)"
        >
          <p>公司新闻</p>
        </div>
        <!-- <div
          :class="navIndex == 2 ? 'nav-bg-color part4-div2' : 'part4-div2'"
          @click="changeNav(2)"
        >
          <p>媒体报道</p>
        </div>
        <div
          :class="navIndex == 3 ? 'nav-bg-color part4-div3' : 'part4-div3'"
          @click="changeNav(3)"
        >
          <p>行业资讯</p>
        </div> -->
      </div>

      <div class="content-item">
        <i class="el-icon-search search-icon" @click="getList"></i>
        <el-input
          @keyup.enter.native="getList"
          placeholder="请输入内容"
          v-model="searchText"
          class="input-with-select"
        >
        </el-input>
      </div>

      <div v-for="item in newMsg" :key="item.id" class="part5" @click="toDetail(item.id)">
        <div class="part5-img">
          <img :src="item.pic" />
        </div>
        <div class="part5-text">
          <div class="part5-text-div1">{{ item.title }}</div>
          <div class="part5-text-div2">{{ item.content | removeTags }}</div>
          <div class="part5-text-div">
            <div class="part5-text-div-div1">
              {{ item.createdTime | formatDate }}
            </div>
            <div class="part5-text-div-div2" >查看更多></div>
          </div>
        </div>
      </div>
      <div class="content-item" style="height: 50px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import global_msg from "../../global";
export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      searchText: "",
      navIndex: 1,
      currentPage: 1,
      total: 40,
      // imgPath: global_msg.imgPath,
      newMsg: [
        {
          id: "",
          title: "",
          content: "",
          type: "",
          createdTime: "",
          updateTime: "",
          createdUser: "",
          updateUser: "",
          author: "",
          pic: '',
        },
      ],
    };
  },
  created() {
    this.onLoadNewList();
  },
  methods: {
    changeNav(i) {
      this.navIndex = i;
      console.log(this.navIndex);
    },
    handleSizeChange(size) {
      console.log(size);
    },
    async onLoadNewList() {
      this.getList()
    },
    async handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList()
    },
    async getList() {
      this.searchText.trim();
      try {
        const { data: res } = await this.$http.get("newController/news", {
          params: {
            pageSize: this.pageSize,
            currentPage: this.currentPage,
            type: 0,
            title: this.searchText,
          },
        });
        if (res.code === 0) {
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
          res.list.forEach(news => {
            news.pictureList.forEach(picture =>{
              news.pic = global_msg.imgPath + picture.pic
            })
          })
          this.newMsg = res.list;
          console.log(this.newMsg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    toDetail(id) {
      this.$router.push({ name: 'News Details', query: { id: id } });
    }
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date
        .toLocaleDateString("zh-CN", options)
        .replace(/\//g, "-")
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$1月$2日");
    },
    removeTags: function (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
};
</script>
<style scoped>
.all {
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  right: 25px;
  top: 14px;
  z-index: 99999;
  font-size: 32px;
  color: #cdcdcd;
  cursor: pointer;
}

.content-item {
  width: 1200px;
  margin: 10px auto;
  margin-bottom: 25px;
  position: relative;
}

.el-pagination {
  position: absolute;
  top: 0;
  right: 0;
}

.input-with-select {
  height: 60px;
}

.input-with-select >>> .el-input__inner {
  height: 100%;
  font-size: 16px;
  /* border: 1px solid #ccc; */
}

.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
}

.part2 {
  width: 100%;
  height: 300px;
  background-image: url("../../src/assets/homepage/矿山1.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  width: 270px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  padding-top: 18px;
  margin: 0 auto;
}

.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 15px;
}

.part4 {
  width: 1200px;
  height: 121px;
  /* border: #23337F 1px solid; */
  margin: 0 auto;
}

.part4-div1 {
  display: inline-block;
  width: 185px;
  height: 40px;
  border-radius: 6px;
  /* margin-left: 305px; */
  margin-top: 41px;
  text-align: center;
  background: #f6f6f6;
}

.nav-bg-color {
  background: #23337f !important;
}

.part4-div1 p {
  margin: 0;
  padding-top: 9px;
}

.part4-div2 {
  display: inline-block;
  width: 185px;
  height: 40px;
  background: #f6f6f6;
  border-radius: 6px;
  margin-left: 18px;
  text-align: center;
}

.part4-div2 p {
  margin: 0;
  padding-top: 9px;
}

.part4-div3 {
  display: inline-block;
  width: 185px;
  height: 40px;
  background: #f6f6f6;
  border-radius: 6px;
  margin-left: 18px;
  text-align: center;
}

.part4-div3 p {
  margin: 0;
  padding-top: 9px;
}

.part5 {
  width: 1200px;
  height: 252px;
  background: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
  margin: 10px auto;
  cursor: pointer;
  border-radius: 8px;
}

.part5-img {
  margin: 32px 48px 32px 32px;
  display: inline-block;
  width: 300px;
  height: 188px;
}

.part5-img img{
  width: 100%;
  height: 100%;
}

.part5-text {
  display: inline-block;
  vertical-align: top;
  width: 732px;
}

.part5-text-div1 {
  width: 656px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 24px;
  margin-top: 45px;
}

.part5-text-div2 {
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-top: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 28px;
}

.part5-text-div {
  margin-top: 33px;
}

.part5-text-div-div1 {
  display: inline-block;
  width: 357px;
  height: 28px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}

.part5-text-div-div2 {
  display: inline-block;
  margin-left: 266px;
  height: 28px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}

.part6 {
  margin-left: 360px;
  margin-top: 30px;
}

.part6-text-div1 {
  width: 404px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3f3f3f;
  line-height: 24px;
  margin-top: 45px;
}

.part6-text-div-div1 {
  display: inline-block;
  width: 357px;
  height: 28px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
</style>
