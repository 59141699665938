<template>
  <div class="all">
    <Header></Header>
    <div class="part2">
      <div class="part2-text1">产品展示</div>
      <!-- <div class="part2-text2">产品展示</div> -->
    </div>
    <div class="part3">
      <div>当前位置：首页 > 产品展示</div>
    </div>
    <el-carousel
      :interval="4000"
      type="card"
      height="500px"
      class="picture_card"
    >
      <el-carousel-item v-for="item in form.pictureList" :key="item.id">
        <img :src="item.pic" class="card_pic" />
        <h3 class="medium">{{ item.sort }}</h3>
      </el-carousel-item>
    </el-carousel>
    <Nav></Nav>
  </div>
</template>

<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import global_msg from "../../global";
export default {
  name: "test",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        imgPath: global_msg.imgPath,
        pictureList: [
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
          {
            id: "",
            pic: "",
            relation: "",
            sort: "",
            type: "",
          },
        ],
      },
    };
  },
  created() {
    this.onLoadImage();
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    async onLoadImage() {
      try {
        const { data: res } = await this.$http.get("newController/pictures", {
          params: { type: 2 },
        });
        if (res.code === 0) {
          this.form.pictureList = res.data;
          this.form.pictureList.forEach((picture) => {
            picture.pic = global_msg.imgPath + picture.pic;
          });
          console.log(this.form.pictureList);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.all {
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.part2 {
  width: 1920px;
  height: 300px;
  background-image: url("../../src/assets/homepage/bg7.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  width: 144px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f4f4f4;
  line-height: 33px;
  padding-top: 18px;
  margin: 0 auto;
  margin-left: 886px;
}

.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  padding-top: 15px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.picture_card {
  margin-bottom: 100px;
}

.card_pic {
  width: 100%;
  height: 100%;
}
</style>
