<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">公开信息</div>
        <!-- <div class="part2-text2">公开信息</div> -->
      </div>
      <div class="part3">
        <div>当前位置：首页 > 信息公开</div>
      </div>
      <div class="content-search">
        <i class="el-icon-search search-icon" @click="getList"></i>
        <el-input
          @keyup.enter.native="getList"
          placeholder="请输入内容"
          v-model="searchText"
          class="input-with-select"
        >
        </el-input>
      </div>
      <!-- 中间内容 -->
      <div class="content">
        <div
          class="profile-item"
          v-for="item in profileMsgs"
          :key="item.id"
          @click="viewPDF(item)"
        >
          <div class="content-left">
            <img src="../assets/homepage/pdf.jpg" alt="" />
          </div>
          <div class="content-right">
            <p>{{ item.fileName }}</p>
            <p style="margin-top: 30px; color: #8f8f8f">
              {{ item.createdTime | formatDate }}
            </p>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="content-item" style="height: 50px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: center"
        >
        </el-pagination>
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>

<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import global_msg from "../../global";
export default {
  name: "publicNews",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      profileMsgs: [],
      searchText: "",
      currentPage: 1,
      total: 1,
      pageSize:10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(size) {
      console.log(size);
      this.pageSize = size;
      this.getList();
    },
    async handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    viewPDF(profile) {
      var url =global_msg.backPath+
        "profileController/previewCheck/" +
        profile.id;
      window.open(url, "_blank", "noopener");
    },
    async getList() {
      this.searchText.trim();
      try {
        const { data: res } = await this.$http.get(
          "profileController/profiles",
          {
            params: {
              pageSize: this.pageSize,
              currentPage: this.currentPage,
              name: this.searchText,
            },
          }
        );
        if (res.code === 0) {
          this.currentPage = res.page.currentPage;
          this.pageSize = res.page.pageSize;
          this.total = res.page.totalSize;
          this.profileMsgs = res.list;
          console.log(this.profileMsgs);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date
        .toLocaleDateString("zh-CN", options)
        .replace(/\//g, "-")
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$1月$2日");
    },
    removeTags: function (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
};
</script>

<style scoped>
.all {
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.part2 {
  width: 100%;
  height: 300px;
  background-image: url("../../src/assets/homepage/bg11.png");
}

.part2-text1 {
  width: 144px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  padding-top: 112px;
  margin: 0 auto;
}

.part2-text2 {
  width: 270px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  padding-top: 18px;
  margin: 0 auto;
}

.part3 {
  margin: 0 auto;
  width: 1200px;
  height: 46px;
  /* border: 1px solid red; */
}

.part3 div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 15px;
}
.content {
  width: 1200px;
  margin: 40px auto;
}

.profile-item {
  width: 500px;
  height: 100px;
  margin: 15px 49px;
  display: inline-block;
  border: 1px solid rgb(161, 160, 160);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 10px rgba(0, 3, 0, 0.2);
  cursor: pointer;
}
.content-item {
  width: 1200px;
  margin: 10px auto;
  margin-bottom: 25px;
  position: relative;
}
.content-search {
  width: 1100px;
  margin: 10px auto;
  margin-bottom: 25px;
  position: relative;
}

.content-left {
  /* display: inline-block; */
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 20px;
}
.content-left img {
  width: 100%;
  height: 100%;
}
.content-right {
  float: left;
  width: 300px;
  height: 100px;
  text-align: center;
}
.content-right p {
  line-height: 20px;
  /* height: 40px; */
}
.search-icon {
  position: absolute;
  right: 25px;
  top: 14px;
  z-index: 99999;
  font-size: 32px;
  color: #cdcdcd;
  cursor: pointer;
}
.input-with-select {
  height: 60px;
}
.input-with-select >>> .el-input__inner {
  height: 100%;
  font-size: 16px;
  /* border: 1px solid #ccc; */
}
</style>
